.error-boundary {
  overflow: hidden;
  padding-bottom: 7.5vh;
  background: darken($color: #ffffff, $amount: 8%);

  .content {
    padding: 30px 15px;
    max-height: 100vh;
    overflow: auto;

    img {
      max-width: 118px;
      margin-bottom: 25px;

      @include media-breakpoint-up(md) {
        margin-bottom: 50px;
      }
    }

    h4 {
      font-size: 22px;
      font-weight: $font-weight-semibold;
      color: $pri;
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        font-size: 28px;
        margin-bottom: 25px;
      }
    }

    p {
      font-size: 18px;
      font-weight: $font-weight-regular;
      color: #000000;
      line-height: 32px;
      margin-bottom: 25px;

      @include media-breakpoint-up(md) {
        font-size: 22px;
        margin-bottom: 50px;
      }
    }

    button {
      padding: 15px 30px;
    }
  }

  .logo {
    bottom: 20px;
    filter: grayscale(100%) brightness(1.7);
    height: 42px;
    display: none;

    @media screen and (min-height: 480px) {
      height: auto;
      display: block;
    }
  }
}
