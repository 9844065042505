* {
  outline: none;
  &:not(input):not(p)::selection {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  max-width: 100vw;

  @include media-breakpoint-down(xs) {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  &.bl-modal-active {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
    position: relative;
  }

  &[data-is-modal-on="true"] {
    position: relative;
    touch-action: none;
    -ms-touch-action: none;
  }
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &[data-is-cookie-disclaimer-on="true"] {
    padding-bottom: 50px;
  }
}

main {
  flex: 1;
  position: relative;
  z-index: 1;
}

.container-fluid {
  // max-width: 1920px;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

p {
  &::selection {
    color: $selectionColor;
    background: $selectionBg;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &::selection {
    color: $selectionColor;
    background: $selectionBg;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
    padding: 0;
  }
}

label {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

input,
select,
textarea {
  border: none;
  background: #fff;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::selection,
  *::selection {
    color: $selectionColor;
    background: $selectionBg;
  }
}

select {
  cursor: pointer;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

button {
  outline: none;

  &:focus,
  &:active,
  &:active {
    outline: none;
  }
}

.selectable {
  &::selection,
  *::selection {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;

    color: $selectionColor;
    background: $selectionBg;
  }
}

.not-selectable {
  &::selection,
  *::selection {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;

    color: inherit;
    background: transparent;
  }
}

.see-all-experiences-button {
  @include priLinearBg($priButtonStart, $priButtonEnd);
  padding: 15px 18px;
  font-size: 16px;
  font-weight: $font-weight-semibold;
  color: #ffffff;
  display: inline-block;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: #ffffff;
  }
}

.color-pri {
  color: $pri;
}

%green-button {
  background: rgb(128, 216, 140);
  background: linear-gradient(
    137deg,
    rgba(128, 216, 140, 1) 0%,
    rgba(109, 212, 152, 1) 60%,
    rgba(56, 202, 187, 1) 100%
  );
  border: none;
  padding: 16px 0;
  font-size: 17px;
  font-weight: $font-weight-bold;
  color: #ffffff;
  line-height: 1;

  &:disabled {
    background: #999999;
  }
}

.bl-content {
  .auth {
    background: transparent;
    width: 100%;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.product-slider-holder {
  .products-holder {
    overflow-x: hidden;
    padding: 30px 0;
  }

  .controls-holder {
    .control {
      width: 50px;
      height: 50px;
      background: #ffffff;
      box-shadow: 0 1px 6px rgba($color: #000000, $alpha: 0.07);
      top: 50%;
      z-index: 3;

      svg {
        path {
          transition: stroke 300ms;
        }
      }

      &.prev {
        left: -7px;
        transform: translate(-50%, -50%);
      }

      &.next {
        right: -7px;
        transform: translate(50%, -50%);
      }

      @include media-breakpoint-down(sm) {
        transform: translate(0, -70%) !important;
      }

      &:hover {
        svg {
          path {
            stroke: $pri;
          }
        }
      }
    }
  }
}

.product-slider-placeholder {
  .products-holder {
    width: max-content;
    overflow: visible;

    @include media-breakpoint-up(md) {
      width: 100%;
    }

    .product-placeholder {
      width: 284px;
      margin: 10px;

      @include media-breakpoint-up(md) {
        width: calc(100% / 4 - 20px);
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .placeholder-item {
        background: #eeeeee;

        &.text-block {
          background: transparent;

          .text-row {
            background: #eeeeee;
          }
        }
      }

      .cover-placeholder {
        padding-top: 70%;
      }
    }
  }
}

.package-placeholder-container {
  width: 100%;
  height: max-content;

  .package-placeholder {
    padding: 25px 15px 20px;

    @include media-breakpoint-up(xl) {
      padding: 25px 40px 30px;
    }

    .placeholder-item {
      background: #eeeeee;

      &.text-block {
        background: transparent;

        .text-row {
          background: #eeeeee;
        }
      }
    }
  }
}

.cookie-disclaimer-holder {
  width: 100vw;
  padding: 10px;
  bottom: 0;
  left: 0;
  background: #333;
  z-index: 4;

  .text {
    font-size: 12px;
    font-weight: $font-weight-medium;
    color: #fbfbf1;
    margin: 0 20px 0 0;
  }

  .more-info-link {
    font-size: 12px;
    font-weight: $font-weight-medium;
    color: #fbfbf1;
    text-decoration: underline;
    text-transform: uppercase;
    min-width: max-content;
    margin: 0 0 10px;

    @include media-breakpoint-up(md) {
      margin: 0 20px 0 0;
    }
  }

  button {
    border: none;
    @include priLinearBg($secButtonStart, $secButtonEnd);
    color: #ffffff;
    padding: 3px 10px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
  }
}

.empty-text {
  font-size: 16px;
  font-weight: $font-weight-medium;
  color: #333;
}

.added-to-cart-modal {
  background: #ffffff;
  overflow-y: auto;
  max-height: calc(90vh - 60px);
  width: 94vw;
  padding: 20px 10px 10px;

  @include media-breakpoint-up(sm) {
    max-height: 90vh;
    max-width: calc(90vw - 60px);
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
    max-width: 940px;
  }

  @include media-breakpoint-up(xl) {
    padding: 40px;
    max-width: 940px;
  }

  .title-area {
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: #000000;
    line-height: 44.5px;
    margin-bottom: 28px;

    svg {
      margin-right: 9px;
    }
  }

  .added-product-holder {
    padding: 17px;
    border: 1px solid #f2f2f2;
    .info-area {
      @include media-breakpoint-up(lg) {
        width: 40%;
        padding-right: 5px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 13px;
        width: 100%;
        padding-right: 15px;
      }
      .cover {
        width: 150px;
        height: 106px;
        margin-right: 23px;

        @include media-breakpoint-down(md) {
          width: 100px;
          height: 71px;
          margin-right: 14px;
        }

        @include media-breakpoint-up(lg) {
          width: 120px;
          height: 86px;
          margin-right: 8px;
        }

        img {
          width: 100%;
        }
      }
      .info {
        @include media-breakpoint-down(md) {
          width: calc(100% - 100px - 14px);
        }

        @include media-breakpoint-up(lg) {
          width: calc(100% - 120px - 8px);
        }
        .title {
          font-size: 15px;
          font-weight: $font-weight-bold;
          color: $pri;
          line-height: 24.3px;
          margin-bottom: 0;

          @include media-breakpoint-down(md) {
            font-size: 13px;
          }
        }

        .product-code,
        %info-text {
          font-size: 16px;
          font-weight: $font-weight-regular;
          color: #3b3b3b;
          line-height: 24.3px;

          @include media-breakpoint-down(md) {
            font-size: 14px;
          }

          span {
            font-weight: $font-weight-semibold;
          }
        }

        .location {
          @extend %info-text;
          margin-top: 3px;
        }

        .price-info {
          @extend %info-text;
          font-size: 14px;
        }
      }
    }

    .control-area {
      margin-left: 0;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 60%;
      }

      .amounts-holder {
        width: 100%;

        @include media-breakpoint-up(lg) {
          flex: 1;
        }

        .amounts {
          width: 100%;

          .amount {
            display: grid;
            grid-template-columns: 50% 20% 30%;
            margin-bottom: 14px;

            @include media-breakpoint-up(lg) {
              margin-left: auto;
            }

            @include media-breakpoint-up(md) {
              width: 100%;
            }
            .type {
              font-size: 16px;
              font-weight: $font-weight-regular;
              color: #000000;
              line-height: 20px;

              @include media-breakpoint-down(md) {
                font-size: 15px;
              }
            }

            .count {
              font-size: 12px;
              font-weight: $font-weight-bold;
              color: #3e3e3e;
              line-height: 20px;

              @include media-breakpoint-down(md) {
                font-size: 11px;
              }
            }

            .price {
              font-size: 16px;
              font-weight: $font-weight-bold;
              color: #000000;
              line-height: 20px;

              @include media-breakpoint-down(md) {
                font-size: 15px;
              }
            }
          }
        }

        .total-price {
          font-size: 16px;
          font-weight: $font-weight-regular;
          color: $pri;
          line-height: 24.3px;

          @include media-breakpoint-down(md) {
            margin-bottom: 14px;
          }

          span {
            font-weight: $font-weight-bold;
          }
        }
      }

      .buttons-holder {
        margin-left: 26px;

        @include media-breakpoint-down(md) {
          margin-left: 0;
          width: 100%;
        }
        .bl-button {
          width: 175px;
          font-size: 13px;
          height: 44px;
          margin-bottom: 10px;
          white-space: nowrap;

          @include media-breakpoint-down(lg) {
            width: 100%;
          }

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .go-to-cart-button {
          @extend %green-button;
          width: 175px;
          font-size: 13px;
          height: 44px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }
  }

  .interesting-experiences-holder {
    margin-top: 16px;
    .title {
      font-size: 20px;
      font-weight: $font-weight-medium;
      color: #000000;
      line-height: 44.5px;
      margin-bottom: 10px;
    }

    .products-holder {
      .bl-single-product {
        width: 283px;
        margin-right: 20px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.loading-dots {
  span {
    width: 16px;
    height: 16px;
    margin: 4px;
    background: $pri;
    border-radius: 50%;
    animation-name: loadingDots;
    animation-duration: 800ms;
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }
  }
}

.loading-dots {
  span {
    width: 16px;
    height: 16px;
    margin: 4px;
    border-radius: 50%;
    animation-name: loadingDots;
    animation-duration: 800ms;
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }
  }
}

.copy-on-click {
  &:after {
    content: attr(data-copied-text);
    width: min-content;
    height: auto;
    background: rgba($color: #000000, $alpha: 0.7);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-50% - 3px));
    color: #fff;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 8px;
    opacity: 0;
    z-index: -1;
    transition: all 200ms;
    text-transform: uppercase;
  }

  &.copied {
    &:after {
      transform: translate(-50%, calc(-100% - 3px));
      opacity: 0.8;
      z-index: 2;
    }
  }

  &.exiting {
    &:after {
      transform: translate(-50%, calc(-150% - 3px));
      opacity: 0;
    }
  }
}

.disabled-tooltip {
  width: max-content;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 15px));
  background: rgba($color: #000000, $alpha: 0.8);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px 4px rgba($color: #000000, $alpha: 0.15);
  opacity: 0;
  z-index: -1;
  transition: opacity 300ms ease 0ms, z-index 0ms ease 310ms;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #f1f1f1;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: rgba($color: #000000, $alpha: 0.8);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
  }

  &.bottom {
    top: auto;
    bottom: 0;
    transform: translate(-50%, calc(100% + 15px));

    &:after {
      bottom: auto;
      top: 0;
      transform: translate(-50%, -100%) rotate(180deg);
    }
  }
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.show-all-button {
  padding: 17px;
  background: #fff7f5;
  font-size: 14px;
  font-weight: $font-weight-medium;
  color: $pri;
  margin-top: 52px;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: $pri;
  }

  svg {
    margin-left: 9px;
  }
}

.bl-confirm-alert {
  .react-confirm-alert {
    .react-confirm-alert-body {
      .react-confirm-alert-button-group {
        button {
          font-size: 15px !important;
          height: auto !important;
          padding: 12px 20px !important;
        }
      }
    }
  }
}

.toast-success {
  background-color: #51a351 !important;
}
.toast-error {
  background-color: #bd362f !important;
}
.toast-info {
  background-color: #2f96b4 !important;
}
.toast-warning {
  background-color: #f89406 !important;
}

.blogs-holder {
  .controls-holder {
    .control {
      width: 50px;
      height: 50px;
      background: #ffffff;
      box-shadow: 0 1px 6px rgba($color: #000000, $alpha: 0.07);
      top: 50%;
      z-index: 3;

      svg {
        path {
          transition: stroke 300ms;
        }
      }

      &.prev {
        left: -7px;
        transform: translate(-50%, -50%);
      }

      &.next {
        right: -7px;
        transform: translate(50%, -50%);
      }

      @include media-breakpoint-down(sm) {
        transform: translate(0, -70%) !important;
      }

      &:hover {
        svg {
          path {
            stroke: $pri;
          }
        }
      }
    }
  }
}

.feed-slider {
  .slider-controls {
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    &.prev {
      left: 5px;

      @include media-breakpoint-up(lg) {
        left: 15px;
      }

      @include media-breakpoint-up(xl) {
        left: 30px;
      }
    }

    &.next {
      right: 5px;

      @include media-breakpoint-up(lg) {
        right: 15px;
      }

      @include media-breakpoint-up(xl) {
        right: 30px;
      }
    }
    .control {
      width: 50px;
      height: 50px;
      background: #ffffff;
      box-shadow: 0 1px 6px rgba($color: #000000, $alpha: 0.07);

      svg {
        path {
          stroke: #000;
          transition: stroke 300ms;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $pri;
          }
        }
      }
    }
  }
}

.cursor {
  &--not-allowed {
    cursor: not-allowed;
  }

  &--pointer {
    cursor: pointer;
  }

  &--default {
    cursor: default;
  }
}
