@import "~normalize.css/normalize.css";
@import "~bootstrap/scss/bootstrap";

@import "typography";
@import "variables";
@import "mixins";
@import "animations";
@import "scaffoldings";

@import "../App/cookieserror";
