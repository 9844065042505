$bg: #ffffff;

$pri: #e75b2b;
$priLight: #fff7f5;

$sec: #ffffff;
$secDark: #3b3b3b;

$successColor: #4bca92;

$selectionColor: $sec;
$selectionBg: $pri;

$footerBg: #161d27;
$footerColor: #ffffff;

$buttonColor: #ffffff;

$priButtonStart: #4ba0ff;
$priButtonEnd: #25c6fc;

$priButtonColor: #44a8ff;

$secButtonStart: $pri;
$secButtonEnd: #feb984;

$dividerColor: #e7e7e7;

$sliderDuration: 6.5s;
