@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap");

.cookies-error-page {
  background: darken($color: #ffffff, $amount: 8%);

  .holder {
    img {
      margin: 0 0 20px;
      width: 170px;

      @include media-breakpoint-up(md) {
        margin: 0 40px 0 0;
      }

      @media screen and (max-height: 580px) {
        display: none;
      }
    }

    .text-area {
      max-width: 90vw;

      @include media-breakpoint-up(md) {
        max-width: calc(70vw - 220px - 40px);
      }

      @include media-breakpoint-up(lg) {
        max-width: 495px;
      }
      h6 {
        font-family: "Hammersmith One", Verdana, Arial, Helvetica, sans-serif;
        font-size: 36px;
        font-weight: $font-weight-semibold;
        color: $pri;

        @include media-breakpoint-up(lg) {
          font-size: 42px;
        }
      }

      p {
        font-size: 20px;
        font-weight: $font-weight-regular;
        color: #000000;

        @include media-breakpoint-up(lg) {
          font-size: 26px;
        }
      }
    }
  }

  .logo {
    bottom: 20px;
    filter: grayscale(100%) brightness(1.7);
    height: 42px;

    @media screen and (min-height: 480px) {
      height: auto;
    }
  }
}
