.bl-button {
  border: none;
  height: 48px;
  font-size: 17px;
  font-weight: $font-weight-bold;
  color: $buttonColor;
  line-height: 1;

  &.bl-pri-button {
    @include priLinearBg($priButtonStart, $priButtonEnd);

    &.opposite {
      background: transparent;
      border: 1px solid $priButtonColor;
      color: $priButtonColor;
    }
  }

  &.bl-sec-button {
    @include priLinearBg($secButtonStart, $secButtonEnd);
  }

  &.bl-alt-button {
    background: rgb(128, 216, 140);
    background: linear-gradient(
      137deg,
      rgba(128, 216, 140, 1) 0%,
      rgba(109, 212, 152, 1) 60%,
      rgba(56, 202, 187, 1) 100%
    );
  }
  &.bl-empty-button {
    background: transparent;
    color: #000000;
    font-weight: $font-weight-regular;
  }

  &:disabled {
    background: rgba($color: #3b3b3b, $alpha: 0.2);

    * {
      cursor: default !important;
    }
  }
}
