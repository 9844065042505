@import "~normalize.css/normalize.css";
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,900&display=swap&subset=latin-ext");

$sliderDuration: 6s;

@mixin mediaXS {
  @media screen and (max-width: 539px) {
    @content;
  }
}

@mixin mediaSM {
  @media screen and (min-width: 540px) and (max-width: 766px) {
    @content;
  }
}

@mixin mediaMD {
  @media screen and (min-width: 767px) and (max-width: 991px) {
    @content;
  }
}

@mixin mediaLG {
  @media screen and (min-width: 992px) {
    @content;
  }
}

body {
  ::-webkit-scrollbar {
    display: none;
  }
}

.Teaser {
  width: 100vw;
  height: 100vh;

  * {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    overflow: hidden;
  }

  .slider-holder {
    .slide {
      z-index: -1;
      opacity: 0;
      transition: opacity 0ms ease 400ms, z-index 0ms ease 0ms;

      &.active {
        z-index: 1;
        opacity: 1;
        transition: opacity 400ms ease 10ms, z-index 0ms ease 1ms;
      }

      img {
        object-fit: cover;
        z-index: 1;
      }

      .overlay {
        left: 0;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.4);
        z-index: 3;
      }
      .slide-info {
        background: rgba($color: #ffffff, $alpha: 0.4);
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 1;
        z-index: 4;
        cursor: default;
      }
    }
  }

  .slider-controls {
    z-index: 4;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-height: 540px) {
      bottom: 5vh;
    }

    .control {
      width: 41px;
      height: 2px;

      &:focus,
      &:active,
      &:hover,
      &:visited {
        text-decoration: none;
      }

      &:before {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: rgba($color: #ffffff, $alpha: 0.3);
        z-index: 1;
      }

      &:after {
        content: "";
        height: 2px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: rgb(232, 91, 44);
        background: linear-gradient(
          148deg,
          rgba(231, 91, 43, 1) 0%,
          rgba(254, 185, 132, 1) 100%
        );
        z-index: 2;
      }

      &.active {
        &:before {
          background: #ffffff;
        }
        &:after {
          animation: sliderControlActive $sliderDuration;
          animation-timing-function: linear;
          animation-iteration-count: 1;
        }
      }

      &.filled {
        &:after {
          width: 100%;
        }
      }
    }
  }

  .content {
    z-index: 6;
    transform: translateY(-5vh);

    .logo {
      height: 10vh;
      margin-bottom: 5vh;
    }

    .motto {
      font-size: 52px;
      font-weight: 900;
      color: #ffffff;
      line-height: 59.4px;
      text-transform: uppercase;
      margin-bottom: 5vh;
      text-shadow: -2px 2px 6px rgba($color: #000000, $alpha: 0.3);

      @include mediaXS {
        font-size: 5vw;
        line-height: 8.5vw;
      }

      @include mediaSM {
        font-size: 28px;
        line-height: 1.4em;

        @media screen and (max-height: 540px) {
          font-size: 5.2vh;
          line-height: 7.4vh;
        }
      }

      @include mediaMD {
        font-size: 40px;

        @media screen and (max-height: 540px) {
          font-size: 7.4vh;
          line-height: 11vh;
        }
      }

      @include mediaLG {
        font-size: 52px;

        @media screen and (max-height: 540px) {
          font-size: 9.6vh;
          line-height: 11vh;
        }
      }

      .special {
        color: #ee7746;
        span {
          color: #ffffff;
        }
      }
    }

    .question {
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 4vh;
      text-shadow: -2px 2px 6px rgba($color: #000000, $alpha: 0.3);
      max-width: 560px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      @include mediaXS {
        font-size: 16px;

        @media screen and (max-height: 540px) {
          font-size: 3.6vw;
        }
      }

      @include mediaSM {
        font-size: 18px;

        @media screen and (max-height: 540px) {
          font-size: 4vh;
        }
      }

      @include mediaMD {
        @media screen and (max-height: 540px) {
          font-size: 4vh;
        }
      }
      @include mediaLG {
        @media screen and (max-height: 540px) {
          font-size: 4.1vh;
        }
      }
    }

    form {
      width: 100%;
      padding: 6px 7px;
      background: #ffffff;
      max-width: 577px;
      text-shadow: -2px 2px 6px rgba($color: #000000, $alpha: 0.3);

      @include mediaSM {
        max-width: 470px;
      }

      input {
        width: calc(100% - 135px - 20px);
        height: 48px;
        margin: 0 10px;
        border: none;
        outline: none;

        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }

        @include mediaXS {
          height: 40px;
          width: calc(100% - 90px - 10px);
          margin: 0 10px 0 0;
        }

        @include mediaSM {
          font-size: 14px;
          height: 40px;
          width: calc(100% - 120px - 20px);
        }
      }

      button {
        height: 48px;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        border: none;
        background: #e75b2b;
        background: linear-gradient(
          148deg,
          rgba(231, 91, 43, 1) 0%,
          rgba(254, 185, 132, 1) 100%
        );
        padding-left: 8px;
        padding-right: 8px;

        @include mediaXS {
          font-size: 14px;
          height: 40px;
          width: max-content;
        }

        @include mediaSM {
          font-size: 14px;
          height: 40px;
        }
      }

      .response {
        left: 0;
        bottom: 0;
        transform: translateY(calc(100% + 5px));
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .sm-links {
      margin-top: 5vh;
      .sm {
        width: 41px;
        height: 41px;
        background: rgba($color: #e75b2b, $alpha: 1);
        text-shadow: -2px 2px 6px rgba($color: #000000, $alpha: 0.3);

        @include mediaXS {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}

@keyframes sliderControlActive {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
